import React, { useState } from 'react';
import styled from 'styled-components';

import Input from 'components/UI/Input';

import IconEye from 'images/eye-off.svg';

const Container = styled.div`
  position: relative;

  img {
    position: absolute;
    top: 18px;
    right: 18px;
    cursor: pointer;
  }
`;

const InputPassword = (props) => {
  const { className, onChange, placeholder, value = '' } = props;

  const [showText, setShowText] = useState(false);

  const handleClickEye = () => {
    setShowText((state) => !state);
  };

  return (
    <Container className={className}>
      <Input
        type={showText ? 'text' : 'password'}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
      />
      <img src={IconEye} onClick={handleClickEye} />
    </Container>
  );
};

export default InputPassword;
