import React from 'react';
import styled from 'styled-components';

const StyledInput = styled.input`
  width: 100%;
  height: 52px;
  background-color: #ffffff;
  border: 1px solid #5fd2da;
  border-radius: 3px;
  padding: 14px 12px;
  font-size: 16px;
  font-weight: 400;
  color: #3b3516;
`;

const Input = (props) => {
  const { className } = props;

  return <StyledInput {...props} className={className} />;
};

export default Input;
