import React, { useState } from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

import Input from 'components/UI/Input';
import InputPassword from 'components/UI/Input/InputPassword';

import { md } from 'utils/breakpoints';

import IconEye from 'images/eye-off.svg';
import IconLogo from 'images/logo.svg';
import IconFB from 'images/fb.svg';
import IconLine from 'images/line-1.svg';
import IconGoogle from 'images/google.svg';

const Btn = styled.div`
  width: 100%;
  height: 58px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
  font-size: 20px;
  font-weight: 700;
  cursor: pointer;
`;

const Container = styled.div`
  width: 482px;
  background-color: #fff;
  border-radius: 8px;
  padding: 64px;
  display: flex;
  flex-direction: column;
  gap: 24px 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  @media (max-width: ${md}) {
    width: 100vw;
    height: 100vh;
    padding: 86px 16px 16px;
  }
`;

const TitleBar = styled.div`
  text-align: center;

  @media (max-width: ${md}) {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 70px;
    background-color: #f2f2f2;
    display: flex;
    align-items: center;
    padding: 0 16px;
  }
`;

const Title = styled.div`
  font-size: 20px;
  font-weight: 700;
  color: #3b3516;

  @media (max-width: ${md}) {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

const FormItem = styled.div`
  &.forgot-wrapper {
    text-align: center;
  }

  &.other-login-methods {
    display: none;

    @media (max-width: ${md}) {
      display: block;
    }
  }
`;

const Text = styled.div`
  font-size: 16px;
  font-weight: 400;
  color: #8185ca;
  line-height: 23px;
`;

const ForgotBtn = styled(Link)`
  font-size: 16px;
  font-weight: 400;
  color: #4d6bf4;
`;

const LoginBtn = styled(Btn)`
  background-color: #5fd2da;
  border: 1px solid #5fd2da;
  color: #fff;
`;

const RegisterBtn = styled(Btn)`
  background-color: #ffffff;
  border: 1px solid #5fd2da;
  color: #5fd2da;
`;

const PhoneWrapper = styled.div`
  margin-bottom: 16px;
`;

const PasswordWrapper = styled.div`
  position: relative;

  img {
    position: absolute;
    top: 18px;
    right: 18px;
    cursor: pointer;
  }
`;

const StyledIconLogo = styled.img`
  display: none;
  width: 28px;
  height: 30px;

  @media (max-width: ${md}) {
    display: block;
  }
`;

const SocialMediaList = styled.div`
  display: flex;
  justify-content: space-between;
`;

const SocialMediaTextWrapper = styled.div`
  position: relative;
  text-align: center;
  margin-bottom: 13px;

  &::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    height: 1px;
    transform: translateY(-50%);
    background-color: rgba(0, 0, 0, 0.25);
  }
`;

const SocialMediaText = styled.div`
  padding: 0 10px;
  font-size: 14px;
  font-weight: 400;
  color: #3b3516;
  position: relative;
  background-color: #fff;
  z-index: 1000;
  display: inline-block;
`;

const Login = (props) => {
  const [password, setPassword] = useState('');

  return (
    <Container>
      <TitleBar>
        <StyledIconLogo src={IconLogo} />
        <Title>登入</Title>
      </TitleBar>
      <FormItem>
        <PhoneWrapper>
          <Input placeholder="請輸入手機號碼" />
        </PhoneWrapper>
        <PasswordWrapper>
          <InputPassword
            placeholder="請輸入密碼"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </PasswordWrapper>
      </FormItem>
      <FormItem>
        <Text>登入問題，請洽客服專線：0809-001-365或各分店服務中心。</Text>
      </FormItem>
      <FormItem className="forgot-wrapper">
        <ForgotBtn to="/">忘記密碼</ForgotBtn>
      </FormItem>
      <FormItem>
        <LoginBtn>登入</LoginBtn>
      </FormItem>
      <FormItem className="other-login-methods">
        <SocialMediaTextWrapper>
          <SocialMediaText>使用其它方式登入</SocialMediaText>
        </SocialMediaTextWrapper>
        <SocialMediaList>
          <img src={IconFB} />
          <img src={IconLine} />
          <img src={IconGoogle} />
        </SocialMediaList>
      </FormItem>
      <FormItem>
        <RegisterBtn>註冊會員</RegisterBtn>
      </FormItem>
    </Container>
  );
};

export default Login;
